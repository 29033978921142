import React from "react"
import NoheaderFooter from "../../../../../components/Noheaderfooter"
import SEO from "../../../../../components/seo"
import TermsBusinessMobile from "../../../../../components/body/pages/mobile/ng/legal/terms/termsBusiness"

const TermsPage = () => (
  <NoheaderFooter>
    <SEO
      canonical={"https://kuda.com/en-ng/legal/terms/business"}
      title="Terms & Conditions Business | Kuda | The Money App for Africans"
      description="Kuda is a london based mobile bank offering Zero card maintenance fees, free transfers, automatic savings."
    />
    <TermsBusinessMobile />
  </NoheaderFooter>
)

export default TermsPage
